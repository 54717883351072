import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/Layout";
import Head from "../components/Head/Head";
import Hero from "../components/Hero/Hero";
import FullViewModal from "../components/FullViewModal/FullViewModal";

import TagDetailsStyles from "./tag-details.module.scss";

const TagDetails = ({ data, location }) => {
  const { pageDetails, tagDetails, allTagsQuery, filteredTattoosQuery } = data;
  const allTags = allTagsQuery.edges.map((edge) => edge.node);
  const filteredTattoos = filteredTattoosQuery.edges.map((edge) => edge.node);

  const [viewFullImage, setViewFullImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const [modalRendered, setModalRendered] = useState(false);
  const [fadingTransition, setFadingTransition] = useState("");

  const findSelectedImage = (imageId, allImages) => {
    const foundImage = allImages.find((image) => image.id === imageId);
    return foundImage;
  };

  const handleShowFullImage = (imageId) => {
    setSelectedImage(findSelectedImage(imageId, filteredTattoos));
    setViewFullImage(true);
  };

  useEffect(() => {
    if (viewFullImage) {
      setModalRendered(true);
      setFadingTransition("fadeIn");
    } else {
      setFadingTransition("fadeOut");
    }
  }, [viewFullImage]);

  const handleCloseModal = () => {
    setViewFullImage(false);
    setModalRendered(false);
  };

  return (
    <Layout>
      <Head
        metaContent={pageDetails.metaContent}
        location={location.pathname}
        dynamicTitle={tagDetails.name}
      />
      <Hero
        heading={pageDetails.pageHeader}
        subheading={tagDetails.name}
        intro={tagDetails?.description?.description}
      />
      <section id="gallery" className={TagDetailsStyles.gallery}>
        <h2>Filter by my different styles:</h2>
        <ul className={TagDetailsStyles.tagList}>
          <li className={TagDetailsStyles.tag}>
            <Link to="/gallery/#gallery" className="spread-underline">
              All Tattoos
            </Link>
          </li>
          {allTags.map((tag) => (
            <li key={tag.id} className={TagDetailsStyles.tag}>
              <Link
                to={`/gallery/${tag.slug}#gallery`}
                className={
                  location.pathname === `/gallery/${tag.slug}`
                    ? "active"
                    : "spread-underline"
                }
              >
                {tag.name}
              </Link>
            </li>
          ))}
        </ul>
        <ul className={TagDetailsStyles.allPhotos}>
          {filteredTattoos.map((tattoo) => (
            <li key={tattoo.id} className={TagDetailsStyles.tattoo}>
              <button
                type="button"
                className={TagDetailsStyles.buttonWrapper}
                onClick={() => handleShowFullImage(tattoo.id)}
                aria-label="View the full image in a separate pop up"
              >
                <GatsbyImage
                  image={getImage(tattoo.photo)}
                  alt={tattoo.photo.description}
                  className={TagDetailsStyles.tattooImage}
                />
              </button>
            </li>
          ))}
        </ul>
      </section>
      {modalRendered && (
        <FullViewModal
          selectedImage={selectedImage}
          fadingTransition={fadingTransition}
          closeModal={handleCloseModal}
        />
      )}
    </Layout>
  );
};

export default TagDetails;

export const query = graphql`
  query ($id: String!) {
    tagDetails: contentfulTag(id: { eq: $id }) {
      name
      description {
        description
      }
      slug
    }
    pageDetails: contentfulGalleryPage {
      metaContent {
        title
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      pageHeader
      pageContent {
        pageContent
      }
    }
    allTagsQuery: allContentfulTag {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    filteredTattoosQuery: allContentfulTattoo(
      filter: { tags: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          name
          photo {
            description
            gatsbyImageData
          }
        }
      }
    }
  }
`;
